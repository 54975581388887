// eslint-disable-next-line import/prefer-default-export
export const fstecHero = {
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/events/demo",
  },
};

export const fstecWhiteGlove = {
  mainTitle: "Powerful tech backed by 5-star service",
  title: "Because business is personal",
  content:
    "Running a restaurant is like doing a dozen jobs at once. We’re here to help. At SpotOn, we create innovative software and point-of-sale tools that let you do things a better way—like take orders and payments right at the table. But we also offer the in-person service and support you deserve to get the most from those tools. Come talk to us at  FSTEC and see for yourself.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/events/demo",
  },
};
export const fstecBoothData = {
  boothInfo: "Learn more at booth 515",
  boothComments:
    "Find SpotOn at booth 515 to learn more about the restaurant tech and personal support that can help keep you, your guests, and your employees happy.",
  boothImg: "fstec-booth.png",
  boothLocation: "Gaylord Texan",
  boothDirections: "1501 Gaylord Trail, Grapevine, TX 76051",
  boothMap:
    "https://www.google.com/maps/place/Gaylord+Texan+Resort+%26+Convention+Center/@32.9545544,-97.0644752,15z/data=!4m2!3m1!1s0x0:0xeafa6054cc95dd4a?sa=X&ved=2ahUKEwjGh9m9mcryAhUqkWoFHQoFCh8Q_BIwHnoECGAQBQ",
};

export const fstecLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle:
        "How to Streamline Your Operations and Improve the Guest Experience",
      blockSubTitle:
        "Catch our educational session where you’ll get first hand insight into everything you need to know about streamlining your operations and improving your guest experience with technology.",
      blockList: [
        "Kevin Bryla, \nHead of Customer Experience for SpotOn",
        "Hillary Holmes, \nGM of Von Elrod’s Beer Hall & Kitchen",
      ],
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/events/demo",
      },
    },
  ],
};

export const showRotarySection = {
  title: "SpotOn across the nation",
  subtext:
    "Our team is on the road to connect with small and midsize businesses operators and learn how we can better serve you.",

  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/",
  },
  // images: [],
  images: [
    {
      image: "fstec-a.png",
      className: "image-wrapper--1",
      thumbnail: "1-thumbnail.png",
    },
    {
      image: "fstec-b.png",
      className: "image-wrapper--2",
      thumbnail: "2-thumbnail.png",
    },
    {
      image: "fstec-c.png",
      className: "image-wrapper--3",
      thumbnail: "3-thumbnail.png",
    },
    {
      image: "fstec-d.png",
      className: "image-wrapper--4",
      thumbnail: "4-thumbnail.png",
    },
    {
      image: "fstec-e.png",
      className: "image-wrapper--5",
      thumbnail: "5-thumbnail.png",
    },
    {
      className: "image-wrapper--6",
      thumbnail: "fstec-f.png",
      image: "fstec-f.png",
      location: "Activmeals",
    },
  ],
};
