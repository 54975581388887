import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import CtaPrimary from "../Buttons/primary-cta";
import Image from "../ImageQuerys/HeroImages";
import CtaSecondary from "../Buttons/secondary-cta";
import EventCtaStyles from "./EventCtaStyles";

const BoothCTA = (props) => {
  const { sectionData } = props;
  return (
    <section className="event-cta">
      <Row gutter={[32, 32]}>
        <Col lg={10} sm={24}>
          <h2 className="event-cta__head">
            {sectionData.boothInfo}
            <span className="blue_dot">.</span>
          </h2>
        </Col>
        <Col lg={14} sm={24}>
          <p style={{ color: "#fff" }}>{sectionData.boothComments}</p>
          <CtaPrimary target="/events/demo" ctaTitle="Learn more" />
        </Col>
      </Row>
      <div className="event-cta__second">
        <Row gutter={[64, 64]}>
          <Col lg={16} sm={24}>
            <Image imageName={sectionData.boothImg} />
          </Col>
          <Col lg={8} sm={24}>
            <h3>{sectionData.boothLocation}</h3>
            <p>{sectionData.boothDirections}</p>
            <CtaSecondary
              target={sectionData.boothMap}
              ctaTitle="Google Maps"
              className="event-cta-maps"
              openInNewTab
            />
            <Image
              imageName="conference-b.png"
              className="event-cta__special-img"
            />
          </Col>
        </Row>
      </div>
      <EventCtaStyles />
    </section>
  );
};

BoothCTA.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
};
BoothCTA.defaultProps = {
  sectionData: "",
};

export default BoothCTA;
